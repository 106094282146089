#landing_page{
  @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
  font-family: "Nunito", sans-serif !important;
  background-color: #0d1611;
  h1,
  h2,
  h4,
  h5,
  h3,
  h6 {
    color: #fff;
  }
  p {
    color: #e2dddd;
    font-size: 16px;
  }

  position: relative;

  //header
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: transparent;
    .navbar {
      padding: 22px 0;
      transition: 0.5s;
      .navbar-toggler {
        background-color: #fff;
        border-color: #fff;
      }
      .navbar-brand {
        // background: #ffffff8c;
        border-radius: 5px;
        padding: 2px;
        img {
          width: 100px;
          height: 61px;
        }
      }
      .navbar-nav {
        .nav-item {
          display: flex;
        }
        li {
          a {
            color: #fff;
            font-size: 16px;
            font-weight: 700;
          }
        }
        .active {
          a {
            border-bottom: 1px solid #b45432;
          }
        }
        .contact {
          display: block;
          background-color: #b45432;
          padding: 0px 20px;
        }
      }
    }
  }
  .navScroll {
    background-color: #b4543282;
    z-index: 99;
    backdrop-filter: blur(10px);
    .navbar {
      padding: 10px 0;
      .navbar-light .navbar-toggler {
        color: #fff;
        &:focus {
          box-shadow: none;
        }
      }
      .navbar-brand {
        color: #fff;
      }
      .navbar-nav {
        .active {
          a {
            border-bottom: 1px solid #fff;
          }
        }
        .contact {
          display: block;
          padding: 0 16px;
          background-color: #fff;
          a {
            color: #b45432;
          }
        }
      }
    }
  }

  // banner
  .hero {
    height: 500px;
    margin-bottom: 90px;
    .hero-inner-left {
      height: 500px;
      .left-content {
        h1 {
          font-size: 56px;
        }
        p {
          max-width: 300px;
          font-size: 16px;
        }
      }
    }
    .hero-inner-right {
      background-image: url("/img/hero-rose.png");
      height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .right-content {
        background-color: #bc4a347a;
        max-width: 250px;
        padding: 25px;
        border-radius: 8px;
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
        p {
          text-align: justify;
          color: #e2dddd;
        }
        a {
          color: #fff;
          svg {
            margin-bottom: 4px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  //services
  .services {
    margin-bottom: 90px;
    .service-box {
      .service-box-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .before-title {
          height: 6px;
          width: 16px;
          background-color: #b45432;
          margin-right: 12px;
        }
        h6 {
          font-size: 20px;
        }
      }
      .service-content {
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  // step
  .step {
    margin-bottom: 90px;
    .step-left-content {
      img {
        width: 95%;
      }
    }
    .step-right-content {
      .ant-steps {
        .ant-steps-item-container {
          .ant-steps-item-tail {
            top: 16.5px;
            padding: 10px 0 4px;
            &::after {
              background-color: #b45432ad;
            }
          }
          .ant-steps-item-icon {
            width: 15px;
            height: 16px;
            .ant-steps-icon-dot {
              border-radius: 0px;
              background-color: #b45432;
              left: -3px;
            }
          }
          .ant-steps-item-content {
            padding-top: 4px;
            .ant-steps-item-title {
              color: #fff;
              font-size: 18px;
            }
            .ant-steps-item-description {
              color: #fff;
            }
          }
        }
      }
    }
  }

  // grow
  .grow {
    margin-bottom: 90px;
    margin-top: 115px;
    background-color: #4b5749;
    padding: 45px 0;
    .grow-bg {
      img {
        width: 100%;
        aspect-ratio: 7/3;
        object-fit: cover;
      }
    }
    .grow-right {
      display: flex;
      .before-title {
        height: 16px;
        margin-right: 9px;
        margin-top: 4px;
        width: 14px;
        background-color: #b45432;
      }
    }
  }

  //benefit section
  .benefit {
    margin-bottom: 90px;
    .benefit-img {
      img {
        width: 100%;
      }
    }
    .benefit-content {
      h6 {
        svg {
          color: #b45432;
          margin-bottom: 7px;
          font-size: 18px;
          margin-right: 6px;
        }
      }
    }
  }

  //reviews
  .testimonials {
    margin-bottom: 90px;
    .single_testimonial:nth-child(odd) {
      .review_text_box {
        background: #b45432;
        position: relative;
        p {
          color: #fff;
        }
        .quota {
          position: absolute;
          height: 40px;
          width: 40px;
          background: #b45432;
          bottom: -17px;
          rotate: 45deg;
          left: 24%;
          border-radius: 5px;
        }
      }
    }
    .single_testimonial {
      .review_text_box {
        background: #fff;
        padding: 40px;
        // height: 146px;
        border-radius: 10px;
        p {
          color: #0d1611;
        }
      }
      .user_identity {
        margin-top: 20px;
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        p.name {
          font-weight: 600;
        }
        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-rating {
          font-size: 12px;
          .ant-rate-star:not(:last-child) {
            margin-right: 3px;
          }
          .ant-rate-star-zero {
            .anticon-star {
              color: #a3a1a1;
            }
          }
        }
      }
    }
    .next_prev_icons {
      position: relative;
      .next_prev_icons_inner {
        z-index: 1;
        position: absolute;
        bottom: 5px;
        right: 0;
        svg {
          color: #b45432;
          font-size: 20px;
          cursor: pointer;
        }
        #prev_btn {
          margin-right: 20px;
        }
        .swiper-button-disabled svg {
          color: gainsboro;
        }
      }
    }
  }

  // contact
  .contact {
    padding-bottom: 90px;
    .section_title {
      margin-bottom: 2.5rem;
      // justify-content: end;
      // h2{
      //   max-width: 66%;
      // }
    }
    .contact-img {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .input_form {
      text-align: end;
      input {
        // min-width: 320px;
        width: 95%;
        border: none;
        border-radius: 25px;
        padding: 16px 25px;
        margin-bottom: 2rem;
      }
      a {
        // min-width: 320px;
        width: 95%;
        margin: auto;
        display: block;
        text-align: center;
        border-radius: 25px;
        border: none;
        padding: 12px 25px;
        background-color: #b45432;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  //gallery
  .gallery {
    margin-bottom: 90px;
  }
  .footer {
    padding: 45px 0 0;
    background-color: #4b5749;
    .upper_section {
      h5 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
      .footer_logo {
        text-align: end;
        img {
          width: 150px;
        }
      }
    }
    .bottom_section {
      .copyright {
        font-weight: 500;
        font-size: 13px;
        color: #fff;
      }
      .policy {
        p {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }

  // common css
  .mini-title {
    color: #fff;
    margin-bottom: 10px;
    h6 {
      font-size: 20px;
    }
  }

  .btn {
    background-color: #b45432;
    padding: 12px 25px;
    color: #fff;
  }
  .section-title {
    font-size: 30px;
    margin-bottom: 35px;
  }

  @media (max-width: 992px) {
    .header {
      background-color: #b45432;
      .navbar {
        padding: 10px 0;
        .navbar-light .navbar-toggler {
          color: #fff;
        }
        .navbar-brand {
          color: #fff;
        }
        .navbar-nav {
          .active {
            a {
              border-bottom: 1px solid #fff;
            }
          }
          .contact {
            display: flex;
            background-color: transparent;
            padding: 0;
            a {
              color: #b45432;
              background-color: #fff;
              padding: 0 20px;
            }
          }
        }
      }
    }
    .hero {
      .hero-inner-left {
        background-image: url("/img/hero-rose.png");
        background-repeat: no-repeat;
        background-position: right;
        .left-content {
          h1 {
            font-size: 40px;
          }
          p {
            max-width: 100%;
            font-size: 16px;
          }
        }
      }
    }
    .testimonials {
      .next_prev_icons_inner {
        bottom: -33px !important;
      }
    }
    .grow {
      .grow-bg {
        img {
          margin-top: 55px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .testimonials {
      .user_identity {
        margin-top: 35px !important;
      }
    }
  }
}
